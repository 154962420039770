/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ModalProps } from './interface';
import './styles.scss';

function Modal({ children, visible, onClose }: ModalProps) {
    useEffect(() => {
        (document as any).documentElement.style.overflow = visible
            ? 'hidden'
            : null;
    }, [visible]);

    const stopPropagationClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    onClick={onClose}
                    className={classNames('modal-layer', {
                        'modal-layer--visible': visible,
                    })}
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    transition={{
                        duration: 0.3,
                    }}
                >
                    <motion.div
                        className='modal'
                        onClick={stopPropagationClick}
                        initial={{
                            opacity: 0,
                            y: 80,
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        transition={{
                            delay: 0.15,
                        }}
                    >
                        <div className='modal__header'>
                            <div
                                onClick={onClose}
                                className='modal__header__close'
                            />
                        </div>
                        <div className='modal__content'>{children}</div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Modal;
