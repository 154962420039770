import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import appConfig from './config';

function configureStartup() {
    // i18n SECTION
    i18next
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next) // bind react-i18next to the instance
        .init({
            backend: {
                // for all available options read the backend's repository readme file
                loadPath: '/locales/{{lng}}.json',
            },
            lng: 'en',
            fallbackLng: 'en',
            debug: appConfig.isDevelopment,
        });
}

export default configureStartup;
