import React from 'react';
import bannerGradient from 'assets/images/methodology_gradient.png';
import methodologyImage from 'assets/images/methodology_banner_image.png';
import imageBlock from 'assets/images/methodology_image_block.png';
import howWeTeachImage from 'assets/images/methodology_how_we_teach.png';
import circleTimeImage from 'assets/images/methodology_circle_time.png';
import learningCentersBenefits from 'assets/images/methodology_learning_centers_benefits.png';
import outdoorPlay from 'assets/images/outdoor_play.png';
import circleRectangle from 'assets/images/forms/circle-rectangle-1.svg';
import halfCircle1 from 'assets/images/forms/half-circle-1.svg';
import circle1 from 'assets/images/forms/circle-1.svg';
import circle2 from 'assets/images/forms/circle-2.svg';

import artCenter from 'assets/icons/centers/art.svg';
import mathCenter from 'assets/icons/centers/math.svg';
import lightTableCenter from 'assets/icons/centers/light-table.svg';
import blockCenter from 'assets/icons/centers/block.svg';
import writingCenter from 'assets/icons/centers/writing.svg';
import storyTellingCenter from 'assets/icons/centers/story-telling.svg';
import puzzleCenter from 'assets/icons/centers/puzzle.svg';
import scienceCenter from 'assets/icons/centers/science.svg';
import dramaticPlayCenter from 'assets/icons/centers/dramatic-play.svg';
import sensoryCenter from 'assets/icons/centers/sensory.svg';
import theatreCenter from 'assets/icons/centers/theatre.svg';
import libraryCenter from 'assets/icons/centers/library.svg';

import rectangleSemiCircle from 'assets/icons/rectangle-semicircle.svg';
import { Trans, useTranslation } from 'react-i18next';
import './styles.scss';
import { motion } from 'framer-motion';
import {
    baseTransition,
    benefitsMotion,
    fadeFromBottomMotion,
    fadeFromLeftMotion,
    fadeFromRightMotion,
    fadeFromTopMotion,
    opacityScaleMotion,
    opacityScaleRotateMotion,
} from 'animations';
import ScrollToTopOrHash from 'components/ScrollToTopOrHash';
import { useLoading } from 'providers/LoadingProvider';
import { getViewportMargin } from 'utils/viewport';

function MethodologyPage() {
    const { loading } = useLoading();
    const { t } = useTranslation();

    return (
        <>
            <ScrollToTopOrHash />
            <div className='methodology'>
                <motion.div
                    className='methodology__banner'
                    initial='rest'
                    style={{
                        backgroundImage: `url(${bannerGradient})`,
                    }}
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{
                        once: true,
                        margin: `-${getViewportMargin(0.6)}px 0px`,
                    }}
                >
                    {/* <img src={bannerGradient} alt='' /> */}
                    <div className='grid-wrapper'>
                        <div />
                        <motion.img
                            className='methodology__banner__icon'
                            src={rectangleSemiCircle}
                            alt=''
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        />
                        <motion.div
                            className='methodology__banner__pre-title'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            {t('homepage.OUR_METHODOLOGY')}
                        </motion.div>
                        <motion.div
                            className='methodology__banner__title'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            {t('homepage.METHODOLOGY_TITLE')}
                        </motion.div>
                        <motion.div
                            className='methodology__banner__text'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            {t('homepage.METHODOLOGY_TEXT')}
                        </motion.div>
                        <div />
                        <motion.img
                            className='methodology__banner__image'
                            src={methodologyImage}
                            alt=''
                            variants={fadeFromRightMotion}
                            transition={baseTransition}
                        />
                    </div>
                    <motion.div
                        className='methodology__banner__form-1'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.5 }}
                    />
                </motion.div>
                <div className='methodology__how-we-teach'>
                    <img loading='lazy' src={howWeTeachImage} alt='' />
                    <motion.div
                        className='grid-wrapper'
                        initial='rest'
                        whileInView={!loading ? 'animated' : ''}
                        viewport={{
                            once: true,
                            margin: `-${getViewportMargin(0.6)}px 0px`,
                        }}
                    >
                        <motion.div
                            className='methodology__how-we-teach__info'
                            variants={fadeFromBottomMotion}
                            transition={baseTransition}
                        >
                            <div className='methodology__how-we-teach__info__pre-title quicksand'>
                                {t('methodology.HOW_WE_TEACH')}
                            </div>
                            <div className='methodology__how-we-teach__info__title'>
                                {t('methodology.HOW_WE_TEACH_TITLE')}
                            </div>
                            <div className='methodology__how-we-teach__info__text'>
                                <Trans i18nKey='methodology.HOW_WE_TEACH_TEXT' />
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
                <motion.div
                    className='methodology__circle-time'
                    initial='rest'
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{
                        once: true,
                        margin: `-${getViewportMargin(0.6)}px 0px`,
                    }}
                >
                    <motion.div
                        className='methodology__circle-time__form-0'
                        variants={opacityScaleRotateMotion}
                        transition={{ ...baseTransition, delay: 0.8 }}
                    />
                    <motion.div
                        className='methodology__circle-time__form-1'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.5 }}
                    />
                    <motion.img
                        className='methodology__circle-time__form-2'
                        src={circle1}
                        alt=''
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.5 }}
                    />
                    <motion.div
                        className='methodology__circle-time__form-3'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.7 }}
                    />
                    <motion.img
                        className='methodology__circle-time__form-4'
                        src={circle2}
                        alt=''
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.6 }}
                    />
                    <div className='grid-wrapper'>
                        <motion.img
                            className='methodology__circle-time__image'
                            src={circleTimeImage}
                            alt=''
                            variants={opacityScaleMotion}
                            transition={baseTransition}
                        />
                        <motion.div
                            className='methodology__circle-time__title'
                            variants={fadeFromRightMotion}
                            transition={baseTransition}
                        >
                            {t('methodology.CIRCLE_TIME')}
                        </motion.div>
                        <motion.div
                            className='methodology__circle-time__text'
                            variants={fadeFromRightMotion}
                            transition={baseTransition}
                        >
                            <Trans i18nKey='methodology.CIRCLE_TIME_TEXT' />
                        </motion.div>
                    </div>
                </motion.div>
                <motion.div
                    className='methodology__circle-time-benefits grid-wrapper'
                    initial='rest'
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{
                        once: true,
                        margin: `-${getViewportMargin(0.4)}px 0px`,
                    }}
                >
                    <div className='methodology__circle-time-benefits__title'>
                        {t('methodology.CIRCLE_TIME_BENEFITS')}
                    </div>
                    <div className='methodology__circle-time-benefits__items'>
                        {Array(6)
                            .fill(0)
                            .map((e, i) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={i}
                                    className='methodology__circle-time-benefits__items__benefit'
                                >
                                    <motion.div
                                        className='methodology__circle-time-benefits__items__benefit__form-wrapper'
                                        whileHover={{
                                            scale: 0.8,
                                        }}
                                        transition={{
                                            duration: 0.4,
                                        }}
                                    >
                                        <motion.div
                                            className={`methodology__circle-time-benefits__items__benefit__form methodology__circle-time-benefits__items__benefit__form-${i}`}
                                            variants={benefitsMotion[i]}
                                            transition={{
                                                ...baseTransition,
                                                delay: 0.2 + 0.5 * i,
                                                duration: 0.5,
                                            }}
                                        />
                                    </motion.div>
                                    <div className='methodology__circle-time-benefits__items__benefit__title'>
                                        {t(
                                            `methodology.CIRCLE_TIME_BENEFIT_${i}`,
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </motion.div>
                <motion.div
                    className='methodology__learning-centers'
                    initial='rest'
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{
                        once: true,
                        margin: `-${getViewportMargin(0.6)}px 0px`,
                    }}
                >
                    <motion.img
                        className='methodology__learning-centers__form-1'
                        src={circleRectangle}
                        alt=''
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.5 }}
                    />
                    <motion.div
                        className='methodology__learning-centers__form-2'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.6 }}
                    />
                    <motion.div
                        className='methodology__learning-centers__form-3'
                        variants={fadeFromRightMotion}
                        transition={{ ...baseTransition, delay: 0.5 }}
                    >
                        <img loading='lazy' src={halfCircle1} alt='' />
                    </motion.div>
                    <motion.div
                        className='methodology__learning-centers__form-4'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.6 }}
                    />
                    <motion.div
                        className='methodology__learning-centers__form-5'
                        variants={fadeFromRightMotion}
                        transition={{ ...baseTransition, delay: 0.7 }}
                    >
                        <img loading='lazy' src={circle1} alt='' />
                    </motion.div>
                    <motion.div
                        className='grid-wrapper'
                        variants={fadeFromTopMotion}
                        transition={baseTransition}
                    >
                        <div className='methodology__learning-centers__title'>
                            {t('methodology.LEARNING_CENTERS')}
                        </div>
                        <div className='methodology__learning-centers__text'>
                            {t('methodology.LEARNING_CENTERS_TEXT')}
                        </div>
                    </motion.div>
                </motion.div>
                <div className='methodology__learning-centers-benefits'>
                    <motion.div
                        className='grid-wrapper'
                        initial='rest'
                        whileInView={!loading ? 'animated' : ''}
                        viewport={{
                            once: true,
                            margin: `-${getViewportMargin(0.6)}px 0px`,
                        }}
                    >
                        <motion.div
                            className='methodology__learning-centers-benefits__title'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            <Trans i18nKey='methodology.LEARNING_CENTERS_BENEFITS' />
                        </motion.div>
                        <motion.div
                            className='methodology__learning-centers-benefits__list'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            <ul>
                                {Array(7)
                                    .fill(0)
                                    .map((e, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <li key={i}>
                                            {t(
                                                `methodology.LEARNING_CENTERS_BENEFITS_ITEM_${
                                                    i + 1
                                                }`,
                                            )}
                                        </li>
                                    ))}
                            </ul>
                        </motion.div>
                        <motion.img
                            src={learningCentersBenefits}
                            alt=''
                            variants={fadeFromRightMotion}
                            transition={baseTransition}
                        />
                    </motion.div>
                </div>
                <div className='methodology__our-learning-centers'>
                    <motion.div
                        className='grid-wrapper'
                        initial='rest'
                        whileInView={!loading ? 'animated' : ''}
                        viewport={{
                            once: true,
                            margin: `-${getViewportMargin(0.6)}px 0px`,
                        }}
                    >
                        <motion.div
                            className='methodology__our-learning-centers__title'
                            variants={fadeFromTopMotion}
                            transition={baseTransition}
                        >
                            {t('methodology.OUR_LEARNING_CENTERS_TITLE')}
                        </motion.div>
                        <motion.div
                            className='methodology__our-learning-centers__text'
                            variants={fadeFromTopMotion}
                            transition={baseTransition}
                        >
                            <Trans i18nKey='methodology.OUR_LEARNING_CENTERS_TEXT' />
                        </motion.div>
                        <div className='methodology__our-learning-centers__forms'>
                            {Array(5)
                                .fill(0)
                                .map((e, i) => (
                                    <motion.div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                        className={`methodology__our-learning-centers__forms__form methodology__our-learning-centers__forms__form-${
                                            i + 1
                                        }`}
                                        variants={opacityScaleMotion}
                                        transition={{
                                            ...baseTransition,
                                            delay: 0.4 + 0.2 * i,
                                        }}
                                    />
                                ))}
                        </div>
                    </motion.div>
                </div>
                <div className='methodology__centers'>
                    <div className='methodology__centers__center methodology__centers__center--blue'>
                        <img loading='lazy' src={artCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_ART' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--purple'>
                        <img loading='lazy' src={mathCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_MATH' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--orange'>
                        <img loading='lazy' src={lightTableCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_LIGHT_TABLE' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--grey'>
                        <img loading='lazy' src={blockCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_BLOCK' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--orange'>
                        <img loading='lazy' src={writingCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_WRITING' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--grey'>
                        <img loading='lazy' src={storyTellingCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_STORY_TELLING' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--blue'>
                        <img loading='lazy' src={puzzleCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_PUZZLE' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--purple'>
                        <img loading='lazy' src={scienceCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_SCIENCE' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--blue methodology__centers__center--two-lines'>
                        <img loading='lazy' src={dramaticPlayCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_DRAMATIC_PLAY' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--purple'>
                        <img loading='lazy' src={sensoryCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_SENSORY' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--orange methodology__centers__center--two-lines'>
                        <img loading='lazy' src={theatreCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_THEATRE' />
                        </div>
                    </div>
                    <div className='methodology__centers__center methodology__centers__center--grey'>
                        <img loading='lazy' src={libraryCenter} alt='' />
                        <div className='methodology__centers__center__text'>
                            <Trans i18nKey='methodology.CENTER_LIBRARY' />
                        </div>
                    </div>
                </div>
                <div className='methodology__image-block'>
                    <img loading='lazy' src={imageBlock} alt='' />
                    <motion.div
                        className='methodology__image-block__form'
                        initial='rest'
                        whileInView={!loading ? 'animated' : ''}
                        viewport={{
                            once: true,
                        }}
                        variants={{
                            rest: {
                                rotate: 0,
                                scale: 0.3,
                                opacity: 0,
                            },
                            animated: {
                                rotate: -30,
                                scale: 1,
                                opacity: 1,
                            },
                        }}
                        transition={baseTransition}
                    />
                </div>

                <motion.div
                    className='methodology__outdoor-play'
                    initial='rest'
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{
                        once: true,
                        margin: `-${getViewportMargin(0.6)}px 0px`,
                    }}
                >
                    <motion.div
                        className='grid-wrapper'
                        initial='rest'
                        whileInView={!loading ? 'animated' : ''}
                        viewport={{
                            once: true,
                            margin: `-${getViewportMargin(0.6)}px 0px`,
                        }}
                    >
                        <motion.div
                            className='methodology__outdoor-play__title'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            {t('methodology.OUTDOOR_PLAY')}
                        </motion.div>
                        <motion.div
                            className='methodology__outdoor-play__text'
                            variants={fadeFromLeftMotion}
                            transition={baseTransition}
                        >
                            {t('methodology.OUTDOOR_PLAY_TEXT')}
                        </motion.div>
                        <motion.img
                            src={outdoorPlay}
                            alt=''
                            variants={fadeFromRightMotion}
                            transition={baseTransition}
                        />
                    </motion.div>
                    <motion.div
                        className='methodology__outdoor-play__form-1'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.5 }}
                    />
                    <motion.div
                        className='methodology__outdoor-play__form-2'
                        variants={opacityScaleRotateMotion}
                        transition={{ ...baseTransition, delay: 0.7 }}
                    />
                    <motion.div
                        className='methodology__outdoor-play__form-3'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.9 }}
                    />
                </motion.div>
            </div>
        </>
    );
}

export default MethodologyPage;
