import React from 'react';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { motion } from 'framer-motion';
import { RequestSuccessPopupProps } from './interface';
import './styles.scss';

const transition = {
    duration: 0.5,
    delay: 0.3,
    ease: 'backOut',
    rotate: {
        duration: 0,
    },
};

const animation = (rotate: number) => ({
    x: 150,
    rotate,
});

function template({ rotate, x }: any) {
    return `rotate(${rotate}) translateX(${x})`;
}

function RequestSuccessPopup({ visible, onClose }: RequestSuccessPopupProps) {
    const { t } = useTranslation();

    return (
        <Modal visible={visible} onClose={onClose}>
            <div className='request-success-popup-content'>
                <div className='request-success-popup-content__check'>
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(5)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-1'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(35)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-2'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(67)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-3'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(100)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-4'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(135)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-5'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(179)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-6'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(220)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-7'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(257)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-8'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(300)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-9'
                    />
                    <motion.div
                        transformTemplate={template}
                        transition={transition}
                        animate={animation(335)}
                        className='request-success-popup-content__check__artifact request-success-popup-content__check__artifact-10'
                    />
                    <div className='request-success-popup-content__check__inner'>
                        <CheckIcon />
                    </div>
                </div>
                <div className='request-success-popup-content__title'>
                    {t('request.SUCCESS')}
                </div>
            </div>
        </Modal>
    );
}

export default RequestSuccessPopup;
