import React, { useContext, useMemo, useState } from 'react';

interface LoadingProviderProps {
    children: JSX.Element;
}

interface ILoadingContext {
    loading?: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = React.createContext<ILoadingContext>({});

export const useLoading = () => useContext(LoadingContext);

function LoadingProvider({ children }: LoadingProviderProps) {
    const [loading, setLoading] = useState(true);
    const value = useMemo<ILoadingContext>(
        () => ({
            loading,
            setLoading,
        }),
        [loading],
    );

    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    );
}

export default LoadingProvider;
