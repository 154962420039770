export const opacityMotion = {
    rest: {
        opacity: 0,
    },
    animated: {
        opacity: 1,
    },
};

export const fadeFromLeftMotion = {
    rest: {
        opacity: 0,
        x: -90,
    },
    animated: {
        opacity: 1,
        x: 0,
    },
};

export const fadeFromRightMotion = {
    rest: {
        opacity: 0,
        x: 90,
    },
    animated: {
        opacity: 1,
        x: 0,
    },
};

export const fadeFromBottomMotion = {
    rest: {
        opacity: 0,
        y: 90,
    },
    animated: {
        opacity: 1,
        y: 0,
    },
};

export const fadeFromTopMotion = {
    rest: {
        opacity: 0,
        y: -90,
    },
    animated: {
        opacity: 1,
        y: 0,
    },
};

export const opacityScaleMotion = {
    rest: {
        opacity: 0,
        scale: 0.3,
    },
    animated: {
        opacity: 1,
        scale: 1,
    },
};

export const opacityScaleRotateMotion = {
    rest: {
        opacity: 0,
        scale: 0.3,
        rotate: 0,
    },
    animated: {
        opacity: 1,
        scale: 1,
        rotate: 45,
    },
};

export const baseTransition = {
    delay: 0.2,
    duration: 0.5,
    ease: 'easeOut',
};

export const benefitsMotion = [
    {
        rest: {
            rotate: 180,
        },
        animated: {
            rotate: 0,
        },
    },
    {
        rest: {
            scale: 0.4,
        },
        animated: {
            scale: 1,
        },
    },
    {
        rest: {
            rotate: -45,
        },
        animated: {
            rotate: 0,
        },
    },
    {
        rest: {
            rotate: 90,
        },
        animated: {
            rotate: 0,
        },
    },
    {
        rest: {
            scale: 1.3,
            rotate: 90,
        },
        animated: {
            scale: 1,
            rotate: 0,
        },
    },
    {
        rest: {
            scale: 0.7,
            rotate: -45,
        },
        animated: {
            scale: 1,
            rotate: 0,
        },
    },
];
