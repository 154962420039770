/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { CirclePartsProps } from './interface';
import './styles.scss';

function CircleParts({
    className,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ...rest
}: CirclePartsProps) {
    const click = useCallback((e: any) => {
        if (onClick) onClick(Number(e.currentTarget.dataset.index));
    }, []);

    return (
        <div
            className={classNames('circle-parts', className)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...rest}
        >
            <div
                className='circle-parts__top-left'
                style={{ backgroundColor: topLeft?.color || '#EA977C' }}
                onClick={click}
                data-index={1}
            >
                {topLeft?.element}
            </div>
            <div
                className='circle-parts__top-right'
                style={{ backgroundColor: topRight?.color || '#EA977C' }}
                onClick={click}
                data-index={2}
            >
                {topRight?.element}
            </div>
            <div
                className='circle-parts__bottom-left'
                style={{ backgroundColor: bottomLeft?.color || '#EA977C' }}
                onClick={click}
                data-index={3}
            >
                {bottomLeft?.element}
            </div>
            <div
                className='circle-parts__bottom-right'
                style={{ backgroundColor: bottomRight?.color || '#EA977C' }}
                onClick={click}
                data-index={4}
            >
                {bottomRight?.element}
            </div>
        </div>
    );
}

export default CircleParts;
