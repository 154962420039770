import Footer from 'containers/Footer';
import HomePage from 'containers/HomePage';
import MethodologyPage from 'containers/MethodologyPage';
import React from 'react';
// import Menu from 'containers/Menu';

export interface IRoute {
    url: string;
    component: React.ReactElement;
}

export const Routes: IRoute[] = [
    {
        url: '/',
        component: (
            <>
                <HomePage />
                <Footer />
            </>
        ),
    },
    {
        url: '/methodology',
        component: (
            <>
                <MethodologyPage />
                <Footer />
            </>
        ),
    },
];
