import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IRoute, Routes as AppRoutes } from 'routes';
import FullPage from 'containers/FullPage';
import configureStartup from 'startup';
import '@material/react-text-field/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/quicksand';
import LoadingProvider from 'providers/LoadingProvider';

configureStartup();

function App() {
    return (
        <Suspense fallback={<div />}>
            <LoadingProvider>
                <BrowserRouter>
                    <FullPage>
                        <Routes>
                            {AppRoutes.map(({ url, component }: IRoute) => (
                                <Route
                                    key={url}
                                    path={url}
                                    element={component}
                                />
                            ))}
                        </Routes>
                    </FullPage>
                </BrowserRouter>
            </LoadingProvider>
        </Suspense>
    );
}

export default App;
