/* eslint-disable react/no-unknown-property */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import bannerImage from 'assets/images/homepage_banner.png';
import serviceImage1 from 'assets/images/homepage_service_1.jpg';
import serviceImage2 from 'assets/images/homepage_service_2.jpg';
import serviceImage3 from 'assets/images/homepage_service_3.jpg';
import serviceImage4 from 'assets/images/homepage_service_4.jpg';
import methodologyImage from 'assets/images/homepage_methodology.png';
import aboutusImage from 'assets/images/aboutus_image.jpg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import rectangleSemiCircle from 'assets/icons/rectangle-semicircle.svg';
import aboutUs from 'assets/icons/about-us.svg';
import galleryIcon from 'assets/icons/gallery.svg';
import inspirationIcon from 'assets/icons/inspiration.svg';
import quoteIcon from 'assets/icons/quote.svg';
import CircleParts from 'components/CircleParts';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './styles.scss';
import ServicesPopup from 'containers/ServicesPopup';
import RequestSuccessPopup from 'containers/RequestSuccessPopup';
import { motion, useInView } from 'framer-motion';
import {
    baseTransition,
    fadeFromLeftMotion,
    fadeFromRightMotion,
    opacityScaleMotion,
} from 'animations';
import { useLoading } from 'providers/LoadingProvider';
import { getViewportMargin } from 'utils/viewport';

const testimonials = [
    {
        message: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL1' />,
        name: 'NL',
    },
    {
        message: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL2' />,
        name: 'JY',
    },
    {
        message: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL3' />,
        name: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL3_NAME' />,
    },
    {
        message: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL4' />,
        name: 'CN',
    },
    {
        message: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL5' />,
        name: 'TV',
    },
    {
        message: <Trans i18nKey='homepage.INSPIRATION_TESTIMONIAL6' />,
        name: 'LS',
    },
];

const services = [
    {
        color: '#EA977C',
        hours: '1h',
    },
    {
        color: '#C3DAD4',
    },
    {
        color: '#AE88BD',
        hours: '3.5h',
    },
    {
        color: '#D4CAC7',
        hours: '1h',
    },
];

function HomePage() {
    const { t } = useTranslation();
    const [service, setService] = useState(1);
    const [gallery, setGallery] = useState<string[]>([]);
    const servicesTimerRef = useRef<NodeJS.Timeout>();
    const [serviceSubmitModalVisible, setServiceSubmitModalVisible] =
        useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const { loading } = useLoading();

    const servicesRef = useRef(null);
    const servicesInView = useInView(servicesRef);

    const servicesSwitch = () => {
        clearTimeout(servicesTimerRef.current);
        servicesTimerRef.current = setTimeout(() => {
            setService(s => (s === services.length ? 1 : s + 1));
            servicesSwitch();
        }, 20000);
    };

    useEffect(() => {
        if (loading) return;

        if (servicesInView) {
            servicesSwitch();
        } else {
            clearTimeout(servicesTimerRef.current);
        }
    }, [servicesInView]);

    useEffect(() => {
        fetch('/php/gallery.php')
            .then(response => response.json())
            .then(data => {
                setGallery(data);
            });
    }, []);

    const servicesMouseEnter = useCallback(() => {
        clearTimeout(servicesTimerRef.current);
    }, []);

    const servicesMouseLeave = useCallback(() => {
        clearTimeout(servicesTimerRef.current);
        servicesSwitch();
    }, []);

    const toggleSuccessModalVisibility = useCallback(() => {
        setSuccessModalVisible(m => !m);
    }, []);

    const closeSubmitModalVisibility = useCallback(() => {
        setServiceSubmitModalVisible(false);
        servicesSwitch();
    }, []);

    const submitModalSuccess = useCallback(() => {
        setServiceSubmitModalVisible(false);
        setSuccessModalVisible(true);
        servicesSwitch();
    }, []);

    const openSubmitModalVisibility = useCallback(() => {
        clearTimeout(servicesTimerRef.current);
        setServiceSubmitModalVisible(true);
    }, []);

    const slidesPerPage =
        // eslint-disable-next-line no-nested-ternary
        window.innerWidth > 880 ? 3 : window.innerWidth < 560 ? 1 : 2;

    return (
        <div className='homepage'>
            <img className='homepage__banner' src={bannerImage} alt='' />
            <div id='services' className='homepage__services'>
                <ServicesPopup
                    visible={serviceSubmitModalVisible}
                    onClose={closeSubmitModalVisibility}
                    onSuccess={submitModalSuccess}
                    service={service}
                />
                <RequestSuccessPopup
                    visible={successModalVisible}
                    onClose={toggleSuccessModalVisibility}
                />
                <motion.div
                    ref={servicesRef}
                    initial='rest'
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{ once: true, margin: '-300px 0px -300px 0px' }}
                    className='grid-wrapper'
                >
                    <motion.div
                        className='homepage__services__icon'
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        <CircleParts
                            topRight={{ color: '#C3DAD4' }}
                            bottomRight={{ color: '#D4CAC7' }}
                            bottomLeft={{ color: '#AE88BD' }}
                        />
                    </motion.div>
                    <motion.div
                        className='homepage__services__pre-title quicksand'
                        style={{ color: services[service - 1].color }}
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        <div>{t('general.SERVICES')}</div>
                    </motion.div>
                    <motion.div
                        className='homepage__services__title'
                        style={{ color: services[service - 1].color }}
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        <div>
                            <Trans
                                components={{
                                    div: <div />,
                                }}
                                i18nKey={`homepage.SERVICES_${service}`}
                            />
                            <span className='homepage__services__title__info'>
                                {services[service - 1].hours
                                    ? `(${services[service - 1].hours}/${t(
                                          'general.WEEK',
                                      )})`
                                    : ''}
                            </span>
                        </div>
                    </motion.div>
                    <motion.div
                        className='homepage__services__text'
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        <Trans i18nKey={`homepage.SERVICES_${service}_TEXT`} />
                    </motion.div>
                    <motion.button
                        type='button'
                        className='homepage__services__signup arrow_link_animation'
                        style={{ color: services[service - 1].color }}
                        onClick={openSubmitModalVisibility}
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        <div>
                            {t('general.SIGN_UP_HERE')} <ArrowRight />
                        </div>
                    </motion.button>
                    <motion.div
                        className={`homepage__services__circle homepage__services__circle--active-${service}`}
                        variants={fadeFromRightMotion}
                        transition={baseTransition}
                    >
                        <CircleParts
                            onClick={setService}
                            onMouseEnter={servicesMouseEnter}
                            onMouseLeave={servicesMouseLeave}
                            topLeft={{
                                element: (
                                    <>
                                        <img
                                            loading='lazy'
                                            src={serviceImage1}
                                            alt=''
                                        />
                                        <div className='homepage__services__circle__top-left quicksand'>
                                            <div>
                                                <Trans
                                                    components={{
                                                        div: <div />,
                                                    }}
                                                    i18nKey='homepage.SERVICES_1'
                                                />
                                            </div>
                                            <div className='homepage__services__circle__top-left__info montserrat'>
                                                {`(${services[0].hours}/${t(
                                                    'general.WEEK',
                                                )})`}
                                            </div>
                                        </div>
                                    </>
                                ),
                            }}
                            topRight={{
                                color: '#C3DAD4',
                                element: (
                                    <>
                                        <img
                                            loading='lazy'
                                            src={serviceImage2}
                                            alt=''
                                        />
                                        <div className='homepage__services__circle__top-right quicksand'>
                                            {t('homepage.SERVICES_2')}
                                        </div>
                                    </>
                                ),
                            }}
                            bottomRight={{
                                color: '#D4CAC7',
                                element: (
                                    <>
                                        <img
                                            loading='lazy'
                                            src={serviceImage4}
                                            alt=''
                                        />
                                        <div className='homepage__services__circle__bottom-right quicksand'>
                                            {t('homepage.SERVICES_4')}
                                            <div className='homepage__services__circle__bottom-right__info montserrat'>
                                                {`(${services[3].hours}/${t(
                                                    'general.WEEK',
                                                )})`}
                                            </div>
                                        </div>
                                    </>
                                ),
                            }}
                            bottomLeft={{
                                color: '#AE88BD',
                                element: (
                                    <>
                                        <img
                                            loading='lazy'
                                            src={serviceImage3}
                                            alt=''
                                        />
                                        <div className='homepage__services__circle__bottom-left quicksand'>
                                            {t('homepage.SERVICES_3')}
                                            <div className='homepage__services__circle__bottom-left__info montserrat'>
                                                {`(${services[2].hours}/${t(
                                                    'general.WEEK',
                                                )})`}
                                            </div>
                                        </div>
                                    </>
                                ),
                            }}
                        />
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className='homepage__methodology'
                initial='rest'
                whileInView={!loading ? 'animated' : ''}
                viewport={{
                    once: true,
                    margin: `-${getViewportMargin(0.6)}px 0px`,
                }}
            >
                <div className='homepage__methodology__image-wrapper'>
                    <img loading='lazy' src={methodologyImage} alt='' />
                </div>
                <div className='homepage__methodology__info'>
                    <motion.img
                        src={rectangleSemiCircle}
                        alt=''
                        variants={fadeFromRightMotion}
                        transition={baseTransition}
                    />
                    <motion.div
                        className='homepage__methodology__info__pre-title quicksand'
                        variants={fadeFromRightMotion}
                        transition={baseTransition}
                    >
                        {t('homepage.OUR_METHODOLOGY')}
                    </motion.div>
                    <motion.div
                        className='homepage__methodology__info__title'
                        variants={fadeFromRightMotion}
                        transition={baseTransition}
                    >
                        {t('homepage.METHODOLOGY_TITLE')}
                    </motion.div>
                    <motion.div
                        className='homepage__methodology__info__text'
                        variants={fadeFromRightMotion}
                        transition={baseTransition}
                    >
                        {t('homepage.METHODOLOGY_TEXT')}
                    </motion.div>
                    <Link
                        to='/methodology'
                        className='homepage__methodology__info__know-more arrow_link_animation'
                    >
                        <div>
                            <motion.div
                                variants={fadeFromRightMotion}
                                transition={baseTransition}
                            >
                                {t('general.KNOW_MORE')} <ArrowRight />
                            </motion.div>
                        </div>
                    </Link>
                    <motion.div
                        className='homepage__methodology__info__blue-circle'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.4 }}
                    />
                    <motion.div
                        className='homepage__methodology__info__purple-circle'
                        variants={opacityScaleMotion}
                        transition={{ ...baseTransition, delay: 0.7 }}
                    />
                </div>
            </motion.div>
            <div id='aboutus' className='homepage__about-us'>
                <motion.div
                    className='grid-wrapper'
                    initial='rest'
                    whileInView={!loading ? 'animated' : ''}
                    viewport={{
                        once: true,
                        margin: `-${getViewportMargin(0.4)}px 0px`,
                    }}
                >
                    <motion.img
                        className='homepage__about-us__icon'
                        src={aboutUs}
                        alt=''
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    />
                    <motion.div
                        className='homepage__about-us__pre-title quicksand'
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        {t('general.ABOUT_US')}
                    </motion.div>
                    <motion.div
                        className='homepage__about-us__title'
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        {t('homepage.ABOUTUS_TITLE')}
                    </motion.div>
                    <motion.div
                        className='homepage__about-us__text'
                        variants={fadeFromLeftMotion}
                        transition={baseTransition}
                    >
                        <Trans i18nKey='homepage.ABOUTUS_TEXT' />
                    </motion.div>
                    <motion.div
                        className='homepage__about-us__image'
                        variants={fadeFromRightMotion}
                        transition={baseTransition}
                    >
                        <img loading='lazy' src={aboutusImage} alt='' />
                    </motion.div>
                </motion.div>
            </div>
            <div id='gallery' className='homepage__gallery'>
                <div className='grid-wrapper'>
                    <img
                        loading='lazy'
                        className='homepage__gallery__icon'
                        src={galleryIcon}
                        alt=''
                    />
                    <div className='homepage__gallery__pre-title quicksand'>
                        {t('general.GALLERY')}
                    </div>
                    <div className='homepage__gallery__title'>
                        {t('homepage.GALLERY_TITLE')}
                    </div>
                    <Slider
                        className='homepage__gallery__slider'
                        arrows={false}
                        slidesToShow={slidesPerPage}
                        autoplay
                    >
                        {gallery.map(img => (
                            <div
                                key={img}
                                className='homepage__gallery__slider__slide'
                            >
                                <a
                                    href={`/gallery/original/${img}`}
                                    target='__blank'
                                >
                                    <img
                                        loading='lazy'
                                        src={`/gallery/${img}`}
                                        alt=''
                                    />
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div id='testimonies' className='homepage__inspiration'>
                <div className='grid-wrapper'>
                    <img
                        loading='lazy'
                        className='homepage__inspiration__icon'
                        src={inspirationIcon}
                        alt=''
                    />
                    <div className='homepage__inspiration__pre-title quicksand'>
                        {t('homepage.OUR_INSPIRATION')}
                    </div>
                    <div className='homepage__inspiration__title'>
                        {t('homepage.INSPIRATION_TITLE')}
                    </div>
                    <div className='homepage__inspiration__quote'>
                        <img loading='lazy' src={quoteIcon} alt='' />
                        <img loading='lazy' src={quoteIcon} alt='' />
                    </div>
                    <Slider
                        className='homepage__inspiration__slider'
                        dots
                        autoplay
                        pauseOnHover={false}
                        autoplaySpeed={15000}
                    >
                        {testimonials.map(({ message, name }, i) => (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                className='homepage__inspiration__slider__slide'
                            >
                                <div className='homepage__inspiration__slider__slide__message'>
                                    {message}
                                </div>
                                <div className='homepage__inspiration__slider__slide__name'>
                                    {name}
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div className='homepage__inspiration__quote homepage__inspiration__quote--right'>
                        <img loading='lazy' src={quoteIcon} alt='' />
                        <img loading='lazy' src={quoteIcon} alt='' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
