/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Input from 'components/Input';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import arrowUp from 'assets/icons/arrow-up.svg';
import arrowRight from 'assets/icons/arrow-right.svg';
import { FooterForm } from './interface';
import './styles.scss';

const DEFAULT_FORM = {
    name: '',
    email: '',
    phone: '',
    address: '',
    message: '',
};

function Footer() {
    const [form, setForm] = useState<FooterForm>(DEFAULT_FORM);
    const { t } = useTranslation();
    const [errors, setErrors] = useState<any>({});

    const updateForm = useCallback((e: any) => {
        setForm(f => ({
            ...(f || ({} as any)),
            [e.target.name]: e.target.value,
        }));
    }, []);

    const checkErrors = () => {
        const err: any = {};
        if (!form.name) err.name = true;
        if (!form.email || !/^[\w._-]+@[\w]+[.][\w]+$/.test(form.email))
            err.email = true;
        if (!form.phone || !/^[+()\d ]+$/.test(form.phone)) err.phone = true;
        if (!form.address) err.address = true;
        if (!form.message) err.message = true;

        return err;
    };

    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const submit = useCallback(() => {
        const err = checkErrors();

        if (Object.keys(err).length) {
            setErrors(err);
            return;
        }

        fetch('/php/contact.php', {
            method: 'POST',
            body: JSON.stringify(form),
        }).then(response => {
            if (!response.ok) {
                // eslint-disable-next-line no-alert
                alert(t('request.ERROR'));
                return;
            }
            setForm(DEFAULT_FORM);
            // eslint-disable-next-line no-alert
            alert(t('request.CONTACT_SUCCESS'));
        });
    }, [form]);

    return (
        <div id='contact' className='footer'>
            <div className='grid-wrapper'>
                <div onClick={scrollToTop} className='footer__arrow'>
                    <img loading='lazy' src={arrowUp} alt='' />
                </div>
                <div className='footer__contactus'>
                    <Trans i18nKey='general.CONTACT_US' />
                </div>
                <div className='footer__form'>
                    <Input
                        className='footer__form__input'
                        value={form?.name || ''}
                        onChange={updateForm as any}
                        name='name'
                        error={errors?.name}
                        label={t('form.NAME')}
                    />
                    <Input
                        className='footer__form__input'
                        value={form?.email || ''}
                        onChange={updateForm as any}
                        name='email'
                        error={errors?.email}
                        label={t('form.EMAIL')}
                    />
                    <Input
                        className='footer__form__input'
                        value={form?.phone || ''}
                        onChange={updateForm as any}
                        name='phone'
                        error={errors?.phone}
                        label={t('form.PHONE')}
                    />
                    <Input
                        className='footer__form__input'
                        value={form?.address || ''}
                        onChange={updateForm as any}
                        name='address'
                        error={errors?.address}
                        label={t('form.ADDRESS')}
                    />
                    <Input
                        className='footer__form__input'
                        value={form?.message || ''}
                        onChange={updateForm as any}
                        name='message'
                        error={errors?.message}
                        label={t('form.MESSAGE')}
                    />
                    <button
                        onClick={submit}
                        type='button'
                        className='footer__form__submit arrow_link_animation'
                    >
                        <div>
                            {t('form.SUBMIT')}{' '}
                            <img loading='lazy' src={arrowRight} alt='' />
                        </div>
                    </button>
                </div>
                <div className='footer__contact'>
                    <div className='footer__contact__title'>
                        {t('general.CONTACT')}
                    </div>
                    <div className='footer__contact__phone'>06 14 43 05 81</div>
                    <div className='footer__contact__email'>
                        <a href='mailto:contact@marvelousminds.fr'>
                            contact@marvelousminds.fr
                        </a>
                    </div>
                    <div className='footer__contact__address'>
                        172 Avenue Paul Vaillant Couturier
                        <br />
                        91700 Sainte Geneviève-des-Bois
                    </div>
                </div>
                <div className='footer__schedule'>
                    <div className='footer__schedule__title'>
                        {t('footer.OPENING_HOURS')}
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.MON')}</div> <span>09:30 - 19:00</span>
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.TUE')}</div> <span>09:00 - 19:00</span>
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.WED')}</div> <span>09:00 - 19:00</span>
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.THU')}</div> <span>09:00 - 19:00</span>
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.FRI')}</div> <span>09:00 - 19:00</span>
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.SAT')}</div> <span>09:30 - 13:00</span>
                    </div>
                    <div className='footer__schedule__day'>
                        <div>{t('weekDay.SUN')}</div>{' '}
                        <span>{t('general.CLOSED')}</span>
                    </div>
                </div>
                <div className='footer__government'>
                    {t('footer.GOVERNMENT')}
                </div>
                <div className='footer__rights'>{t('footer.RIGHTS')}</div>
            </div>
        </div>
    );
}

export default Footer;
