import React from 'react';
import TextField, { Input as InputText } from '@material/react-text-field';
import classNames from 'classnames';
import { InputProps } from './interface';
import './styles.scss';

function Input({
    value,
    onChange,
    name,
    label,
    className,
    disabled,
    error,
}: InputProps) {
    return (
        <TextField
            label={label}
            dense
            className={classNames('input', className, {
                'input--error': error,
            })}
        >
            <InputText
                disabled={disabled}
                value={value}
                name={name}
                onChange={onChange as any}
            />
        </TextField>
    );
}

export default Input;
