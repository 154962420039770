import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import logo from 'assets/images/logo.svg';
import menuGradient from 'assets/images/menu.png';
import instagram from 'assets/icons/instagram.svg';
import facebook from 'assets/icons/facebook.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useScroll } from 'framer-motion';
import './styles.scss';

const LEFT_LINKS = [
    {
        text: <Trans i18nKey='general.SERVICES' />,
        to: '/#services',
    },
    {
        text: <Trans i18nKey='general.METHODOLOGY' />,
        to: '/methodology',
    },
    {
        text: <Trans i18nKey='general.ABOUT_US' />,
        to: '/#aboutus',
    },
];

const RIGHT_LINKS = [
    {
        text: <Trans i18nKey='general.GALLERY' />,
        to: '/#gallery',
    },
    {
        text: <Trans i18nKey='general.TESTIMONIES' />,
        to: '/#testimonies',
    },
    {
        text: <Trans i18nKey='general.CONTACT' />,
        to: '/#contact',
    },
];

const LANGS = ['fr', 'en'];

function Menu() {
    const { scrollY } = useScroll();
    const { i18n } = useTranslation();
    const [mobileMenuActive, setMenuActive] = React.useState(false);
    const gradientRef = useRef<HTMLImageElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const closeMenu = () => {
        if (mobileMenuActive) setMenuActive(false);
    };

    const changeLang = (e: any) => {
        const { dataset } = e.currentTarget;
        const { lang } = dataset;
        i18n.changeLanguage(lang);
        closeMenu();
    };

    const toggleMobileMenu = () => {
        if (!mobileMenuActive)
            document.documentElement.style.overflow = 'hidden';
        else (document as any).documentElement.style.overflow = null;
        setMenuActive(!mobileMenuActive);
    };

    useEffect(() => {
        return scrollY.onChange((latest: number) => {
            const fullHeight =
                document.documentElement.offsetHeight - window.innerHeight;

            const y = -(
                (latest *
                    (99 -
                        (menuRef.current!.offsetHeight * 100) /
                            gradientRef.current!.offsetHeight)) /
                fullHeight
            );
            gradientRef.current!.style.transform = `translateY(${y}%)`;
            if (latest === 0)
                menuRef.current!.classList.remove('menu--scrolled');
            else menuRef.current!.classList.add('menu--scrolled');
        });
    }, []);

    return (
        <>
            <div ref={menuRef} className='menu'>
                <img
                    ref={gradientRef}
                    className='menu__gradient'
                    src={menuGradient}
                    alt=''
                />
                <div className='menu__edge'>
                    <button
                        type='button'
                        className='menu__edge__hamburguer'
                        onClick={toggleMobileMenu}
                    >
                        <div className='menu__edge__hamburguer__line' />
                        <div className='menu__edge__hamburguer__line' />
                        <div className='menu__edge__hamburguer__line' />
                    </button>
                    <a
                        className='menu__social'
                        target='__blank'
                        href='https://www.facebook.com/profile.php?id=61558418605480&mibextid=ZbWKwL'
                    >
                        <img src={facebook} alt='' />
                    </a>
                    <a
                        className='menu__social'
                        target='__blank'
                        href='https://www.instagram.com/marvelousmindsenglish?igsh=bjQyb29rYmY4MXFk'
                    >
                        <img src={instagram} alt='' />
                    </a>
                </div>
                <div className='menu__center'>
                    <div className='menu__center__links'>
                        {LEFT_LINKS.map(({ text, to }) => (
                            <Link key={`${to}-${i18n.language}`} to={to}>
                                {text}
                            </Link>
                        ))}
                    </div>
                    <Link to='/'>
                        <img src={logo} alt='' />
                    </Link>
                    <div className='menu__center__links'>
                        {RIGHT_LINKS.map(({ text, to }) => (
                            <Link key={`${to}-${i18n.language}`} to={to}>
                                {text}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className='menu__edge menu__edge--right'>
                    {LANGS.map(lang => (
                        <button
                            key={lang}
                            onClick={changeLang}
                            type='button'
                            data-lang={lang}
                            className={classNames('menu__lang', {
                                'menu__lang--active': i18n.language === lang,
                            })}
                        >
                            {lang}
                        </button>
                    ))}
                </div>
            </div>
            <div
                className={classNames('mobile-menu', {
                    'mobile-menu--active': mobileMenuActive,
                })}
            >
                <button
                    type='button'
                    className='mobile-menu__close'
                    onClick={toggleMobileMenu}
                >
                    <div className='mobile-menu__close__line' />
                    <div className='mobile-menu__close__line' />
                </button>

                <div className='mobile-menu__center'>
                    <Link className='mobile-menu__center__logo' to='/'>
                        <img src={logo} alt='' />
                    </Link>
                    <div className='mobile-menu__center__links'>
                        {LEFT_LINKS.map(({ text, to }) => (
                            <Link
                                key={`${to}-${i18n.language}`}
                                to={to}
                                onClick={closeMenu}
                            >
                                {text}
                            </Link>
                        ))}
                        {RIGHT_LINKS.map(({ text, to }) => (
                            <Link
                                key={`${to}-${i18n.language}`}
                                to={to}
                                onClick={closeMenu}
                            >
                                {text}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className='mobile-menu__edge'>
                    {LANGS.map(lang => (
                        <button
                            key={lang}
                            onClick={changeLang}
                            type='button'
                            data-lang={lang}
                            className={classNames('menu__lang', {
                                'menu__lang--active': i18n.language === lang,
                            })}
                        >
                            {lang}
                        </button>
                    ))}
                </div>
                <div className='mobile-menu__socials'>
                    <a
                        target='__blank'
                        href='https://www.facebook.com/profile.php?id=61558418605480&mibextid=ZbWKwL'
                    >
                        <img src={facebook} alt='' />
                    </a>
                    <a
                        target='__blank'
                        href='https://www.instagram.com/marvelousmindsenglish?igsh=bjQyb29rYmY4MXFk'
                    >
                        <img src={instagram} alt='' />
                    </a>
                </div>
            </div>
        </>
    );
}

export default Menu;
