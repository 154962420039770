import ScrollToTopOrHash from 'components/ScrollToTopOrHash';
import Loading from 'containers/Loading';
import Menu from 'containers/Menu';
import { AnimatePresence } from 'framer-motion';
import { useLoading } from 'providers/LoadingProvider';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageProps } from './interface';

function FullPage({ children }: FullPageProps) {
    const { ready } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { setLoading: setFullyLoading } = useLoading();

    useEffect(() => {
        if (!ready) return;

        document.documentElement.style.overflow = 'hidden';
        setTimeout(() => {
            setLoading(false);
            setTimeout(() => {
                (document as any).documentElement.style.overflow = null;
                setFullyLoading!(false);
            }, 650);
        }, 2500);
    }, [ready]);

    if (!ready) return null;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
        <>
            <AnimatePresence>{loading && <Loading />}</AnimatePresence>
            {!loading && <ScrollToTopOrHash />}
            <Menu />
            {children}
        </>
    );
}

export default FullPage;
