import Input from 'components/Input';
import Modal from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ServicesPopupForm, ServicesPopupProps } from './interface';
import './styles.scss';

const SERVICES_COLORS = ['#EA977C', '#C3DAD4', '#AE88BD', '#D4CAC7'];

const DEFAULT_FORM = {
    service: '',
    name: '',
    email: '',
    phone: '',
};

function ServicesPopup({
    visible,
    onClose,
    service,
    onSuccess,
}: ServicesPopupProps) {
    const { t } = useTranslation();
    const [form, setForm] = useState<ServicesPopupForm>(DEFAULT_FORM);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        setForm(f => ({
            ...f,
            service: t(`homepage.SERVICES_${service}`)
                .replace('<div>', '')
                .replace('</div>', ''),
        }));
    }, [service]);

    const updateForm = useCallback((e: any) => {
        const { name, value } = e.target;
        setForm(f => ({ ...f, [name]: value }));
    }, []);

    const checkErrors = () => {
        const err: any = {};
        if (!form.name) err.name = true;
        if (!form.email || !/^[\w._-]+@[\w]+[.][\w]+$/.test(form.email))
            err.email = true;
        if (!form.phone || !/^[+()\d ]+$/.test(form.phone)) err.phone = true;

        return err;
    };

    const submit = useCallback(() => {
        const err = checkErrors();

        if (Object.keys(err).length) {
            setErrors(err);
            return;
        }

        fetch('/php/service.php', {
            method: 'POST',
            body: JSON.stringify(form),
        }).then(response => {
            if (!response.ok) {
                // eslint-disable-next-line no-alert
                alert(t('request.ERROR'));
                return;
            }
            onSuccess();
            setForm({
                ...DEFAULT_FORM,
                service: t(`homepage.SERVICES_${service}`),
            });
        });
    }, [form]);

    return (
        <Modal visible={visible} onClose={onClose}>
            <div
                className='services-popup-content'
                style={{ color: SERVICES_COLORS[service ? service - 1 : 0] }}
            >
                <div className='services-popup-content__title'>
                    {t('homepage.LET_ADVENTURE_BEGIN')}
                </div>
                <div className='services-popup-content__text'>
                    {t('homepage.FILL_FIELDS')}
                </div>
                <Input
                    label={t('form.SERVICE')}
                    value={form.service}
                    disabled
                />
                <Input
                    label={t('form.NAME')}
                    name='name'
                    value={form.name}
                    error={errors?.name}
                    onChange={updateForm as any}
                />
                <Input
                    label={t('form.EMAIL')}
                    name='email'
                    value={form.email}
                    error={errors?.email}
                    onChange={updateForm as any}
                />
                <Input
                    label={t('form.PHONE')}
                    name='phone'
                    value={form.phone}
                    error={errors?.phone}
                    onChange={updateForm as any}
                />
                <div className='services-popup-content__submit-wrapper'>
                    <button
                        className='services-popup-content__submit'
                        type='button'
                        onClick={submit}
                    >
                        {t('form.SUBMIT')} <ArrowRight />
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ServicesPopup;
