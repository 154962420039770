import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

function ScrollToTopOrHash() {
    const { hash, pathname } = useLocation();

    useEffect(() => {
        if (hash) {
            const elementToScroll = document.getElementById(
                hash?.replace('#', ''),
            );

            if (!elementToScroll) {
                window.scrollTo(0, 0);

                return;
            }

            window.scrollTo({
                top: elementToScroll.offsetTop - 92,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo(0, 0);
        }
    }, [hash, pathname]);

    return null;
}

export default ScrollToTopOrHash;
