import React, { useEffect, useState } from 'react';
import svg1 from 'assets/icons/loading/1.svg';
import svg2 from 'assets/icons/loading/2.svg';
import svg3 from 'assets/icons/loading/3.svg';
import svg4 from 'assets/icons/loading/4.svg';
import svg5 from 'assets/icons/loading/5.svg';
import './styles.scss';
import { motion } from 'framer-motion';

function Loading() {
    const [state, setState] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setState(s => (s === 5 ? 1 : s + 1));
        }, 500);
    }, [state]);

    return (
        <div className='loading'>
            <motion.div
                className='loading__left'
                exit={{
                    x: '-200%',
                    rotate: -20,
                }}
                transition={{
                    duration: 0.9,
                    ease: 'easeInOut',
                    delay: 0.3,
                }}
            />
            <motion.div
                className='loading__right'
                exit={{
                    x: '270%',
                    rotate: -40,
                }}
                transition={{
                    duration: 0.9,
                    ease: 'easeInOut',
                    delay: 0.3,
                }}
            />
            <motion.div
                className={`loading-state loading-${state}`}
                exit={{
                    opacity: 0,
                    scale: 0.2,
                    rotate: -360,
                }}
                transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                }}
            >
                <img src={svg1} alt='' />
                <img src={svg2} alt='' />
                <img src={svg3} alt='' />
                <img src={svg4} alt='' />
                <img src={svg5} alt='' />
            </motion.div>
        </div>
    );
}

export default Loading;
